<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">抽獎作業</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="onGridAdd"
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
          @save="onGridSave"
        >
          <!-- <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template> -->
          <template #column-="{ row }">
            <div class="flex justify-center" v-if="row.Status === 0">
              <button
                class="button rounded-full bg-theme-11 text-white"
                title="抽獎"
                @click="doAction(row)"
              >
                <FontAwesome icon="dice" class="w-4 h-4" />
              </button>
            </div>
            <div class="flex justify-center" v-if="row.Status === 1">
              <label class="text-green-600">已抽獎</label>
            </div>
          </template>

          <template #modal="{ row, submit, reset }">
            <div class="flex">
              <vxe-form
                ref="form"
                class="w-full"
                v-bind="formOptions"
                :data="row"
                @submit="submit"
                @reset="reset"
              >
                <template #column-category-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.CategoryId"
                    v-bind="categorySelectOptions"
                  />
                </template>
                <template #column-product-id="{ data }">
                  <SelectBox
                    :transfer="true"
                    v-model="data.ProductId"
                    v-bind="productSelectOptions"
                  />
                </template>
                <template #column-channel>
                  <SelectBox
                    :transfer="true"
                    v-model="channels"
                    v-bind="channelSelectOptions"
                  />
                </template>
              </vxe-form>
            </div>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </Grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import CloudFun, {
  computed,
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
// import ProductAttributeGrid from "@/cloudfun/components/ProductAttributeGrid.vue";
import CategoryAttributeGrid from "@/cloudfun/components/CategoryAttributeGrid.vue";
import { VueUploadItem } from "vue-upload-component";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const characteristicEditor = ref();
    const specificationEditor = ref();
    const descriptionEditor = ref();
    const productAttributeValues = ref([]);
    const channels = ref<any>([]);
    const editingRow = ref(null);
    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" }
    ];

    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );

    const gridOptions: GridOptions = {
      title: "產品資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "產品清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Category.Name",
          title: "抽獎類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.Name",
          title: "獎項名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Time",
          title: "抽獎日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "Qty",
          title: "得獎人數",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "right",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Status",
          title: "抽獎作業",
          align: "center",
          width: 100,
          slots: { default: "column-" }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("lottery/query", params)
          : undefined,
        queryAll: model
          ? params => model.dispatch("lottery/query", params)
          : undefined,
        save: (params: any) => {
          console.log(
            "🚀 ~ file: Main.vue ~ line 334 ~ setup ~ params",
            params
          );

          if (params.insertRows && params.insertRows.length > 0) {
            for (const item of channels.value) {
              params.insertRows[0].Channels.push({
                LotteryId: 0,
                ChannelId: item
              });
            }
          }

          if (params.updateRows && params.updateRows.length > 0) {
            params.updateRows[0].Channels = [];
            for (const item of channels.value) {
              params.updateRows[0].Channels.push({
                LotteryId: 0,
                ChannelId: item
              });
            }
          }

          // if (params.deleteRows.length > 0) {
          //   params.deleteRows[0].Channels = [];
          //   for (const item of channels.value) {
          //     params.deleteRows[0].Channels.push({
          //       LotteryId: 0,
          //       ChannelId: item
          //     });
          //   }
          // }

          // console.log(
          //   "🚀 ~ file: Main.vue ~ line 339 ~ setup ~ params",
          //   params
          // );

          return new Promise((resolve, reject) =>
            model?.dispatch("lottery/save", params).then(
              payload => {
                editingRow.value = null;
                resolve(payload);
              },
              reason => {
                CloudFun.send("error", {
                  subject: "保存失敗",
                  content: reason
                });
                reject(reason);
              }
            )
          );
        }
      },
      modalConfig: { width: "70%", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "CategoryId",
          title: "抽獎類別",
          span: 24,
          slots: { default: "column-category-id" }
        },
        {
          field: "CategoryId",
          title: "獎項",
          span: 24,
          slots: { default: "column-product-id" }
        },
        {
          field: "Time",
          title: "抽獎日期",
          span: 24,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "請輸入抽獎日期" }
          }
        },

        {
          field: "StartDate",
          title: "填寫開始日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "請輸入日期" }
          }
        },
        {
          field: "EndDate",
          title: "填寫結束日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "請輸入日期" }
          }
        },
        {
          field: "Id",
          title: "通路",
          span: 24,
          slots: { default: "column-channel" }
        },
        {
          field: "Qty",
          title: "抽出筆數",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入抽出筆數" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        }
      ],
      rules: {
        Name: [{ required: true }],
        CategoryId: [{ required: true }],
        ProductId: [{ required: true }],
        // CountryId: [{ required: true }],
        Qty: [{ type: "number", required: true, message: "抽出筆數" }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇獎項",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("product/find", row.Id); // eslint-disable-line
        return entity.Name;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("product/find", value), // eslint-disable-line
        query: params => model!.dispatch("product/query", params) // eslint-disable-line
      }
    };

    const channelSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇標籤",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      addIfNotExists: true,
      promises: {
        find: value => model!.dispatch("channel/find", value), // eslint-disable-line
        query: params => model!.dispatch("channel/query", params), // eslint-disable-line
        insert: row => model!.dispatch("channel/insert", row) // eslint-disable-line
      }
    };

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Ordinal",
          title: "順序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }]
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categorySelectOptions,
      channelSelectOptions,
      productSelectOptions,
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      showSpecification: ref(true),
      showCharacteristic: ref(true),
      characteristicEditor,
      specificationEditor,
      descriptionEditor,
      productAttributeValues,
      channels,
      isEditing,
      editingRow
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.Status = 0;
      row.Channels = [];
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("lottery/find", row.Id)
        : undefined;

      this.channels = [] as any[];
      if (entity && entity.Channels) {
        for (const item of entity.Channels) {
          this.channels.push(item.ChannelId);
        }
      }
      if (entity) Object.assign(row, entity);

      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridSave(row: any, callback: any) {
      // if (row.insertRecords.length > 0) {
      //   for (const item of this.channels) {
      //     row.insertRecords[0].Channels.push({ LotteryId: 0, ChannelId: item });
      //   }
      // }

      // if (row.updateRows.length > 0) {
      //   row.updateRows[0].Channels = [];
      //   for (const item of this.channels) {
      //     row.updateRows[0].Channels.push({ LotteryId: 0, ChannelId: item });
      //   }
      // }

      callback();
    },
    async doAction(row: any) {
      console.log("🚀 ~ file: Main.vue ~ line 688 ~ doAction ~ row", row);

      if (row.Status === 0) {
        if (confirm("確定進行抽獎？")) {
          this.$model.dispatch("lottery/doAction", row).then(
            payload => {
              console.log("payload", payload);
              alert("完成抽獎作業");
              this.grid.refresh();
            },
            error => {
              console.log("error", error);
            }
          );
        }
      }
    }
  }
});
</script>
